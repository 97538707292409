import * as styles from './modal.module.scss'

import { graphql, useStaticQuery } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

const PopupModal = ({ open }) => {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: {eq: "popup-geschenke.jpg"}) {
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 65
            width: 323
          )
        }
      }
    }
  `)

  return (
    <div className={styles.modal} data-open={open}>
      <div className={styles.title}>Last-Minute-Weihnachtsgeschenke 🎄</div>
      <div className={styles.body}>Noch auf der Suche nach einem Geschenk? Bestelle noch bis zum 19.12.24 bei uns im Shop und erhalte vor Weihnachten dein Kochbuch, T-Shirt oder Magazin.</div>
      <div className={styles.image}>
        <GatsbyImage
          image={image?.childImageSharp?.gatsbyImageData}
          alt="Unser Adventskalender ist zurück!"
          style={{ maxWidth: "100%" }}
        />
      </div>
      <a target='_blank' className={styles.button} href="https://shop.zuckerjagdwurst.com">Zum Shop</a>
    </div>
  )
}

export default PopupModal
