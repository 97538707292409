import { graphql, useStaticQuery } from "gatsby"

import React from "react"
import { Helmet } from "react-helmet"
import favicon from '../../images/favicon.ico'

function Seo({
  locale = 'de',
  localizedUrls = [],
  title = '',
  sharingTitle = '',
  metaTitle = '',
  sharingDescription = '',
  metaDescription = '',
  keywords = [],
  image = '',
  noIndex = false
}) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            siteUrl
          }
        }
      }
    `
  )

  const currentUrl = site.siteMetadata.siteUrl + localizedUrls[locale]
  const sharingImage = `${image}?w=1200`
  // what happens if there are none?
  const links = Object.entries(localizedUrls).map(localizedUrl => {
    const href = localizedUrl[0]
    const hrefLang = site.siteMetadata.siteUrl + localizedUrl[1]

    return {
      rel: 'alternate', href: href, hrefLang: hrefLang
    }
  }).filter(n => n)

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.siteTitle}`}
      link={[
        {
          href: currentUrl,
          rel: "canonical"
        },
        {
          rel: "icon", type: "image/ico", sizes: "16x16", href: `${favicon}`
        },
        ...links
      ]}
      meta={[
        noIndex ? {
          name: 'robots',
          content: 'noindex,nofollow'
        } : {},
        noIndex ? {
          name: 'googlebot',
          content: 'noindex,nofollow'
        } : {},
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: sharingTitle,
        },
        {
          property: `og:description`,
          content: sharingDescription,
        },
        {
          property: `og:image`,
          content: sharingImage,
        },
        {
          property: `og:url`,
          content: currentUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:site_name/>`,
          content: site.siteMetadata.siteUrl
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: sharingTitle,
        },
        {
          name: `twitter:description`,
          content: sharingDescription,
        },
        {
          name: `twitter:image`,
          content: sharingImage,
        },
        {
          name: `twitter:image:alt`,
          content: sharingTitle,
        }
      ].filter(tag => Object.keys(tag).length > 0)
        .concat(
          (keywords !== null && keywords.length > 0)
            ? {
              name: `keywords`,
              content: keywords,
            }
            : []
        )}
    />
  )
}

export default Seo
